import Router from './config/routing/Router'

function App() {
  	return (
    	<div className='App'>
      		<Router />
    	</div>
  	)
}

export default App