import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

import Flat from '../../resources/images/calculator/RoofType/Flat.png'
import Slate from '../../resources/images/calculator/RoofType/Slate.png'
import Tile from '../../resources/images/calculator/RoofType/Tile.png'

const options = [
    {value: 'Flat Roof', image: Flat},
    {value: 'Slate Roof', image: Slate},
    {value: 'Tiled Roof', image: Tile}
]

export default function RoofType(props) {
    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='stretch' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What kind of roof do you have?</h2>
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={6} sm={4} key={i}>
                        <Box className={getClassName(option.value)} elevation={2} onClick={() => updateAnswer(option.value)}>
                            <img src={option.image} className='calc-image' />
                            <p>{option.value}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}