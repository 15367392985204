import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'

export default function Eircode(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What's your Eircode?</h2>
                <p className='question-subtext'>We will review your roof in Google Maps to make sure your pitch and roof direction are eligible for solar</p>
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    //label='Last Name'
                    color={props.form === 'solarfinder' ? 'warning' : 'success'} 
                    variant='outlined' 
                    type='text'
                    value={props.eircode} 
                    placeholder='A65 F4E2'
                    size='large'
                    onChange={(e) => props.setEircode(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
            {props.hasError &&
                <p className='error-text' style={{marginTop: '1rem'}}>Please enter a valid eircode, you can find yours <a target='_blank' href='https://finder.eircode.ie'>here</a> if you are unsure</p>
            }  
        </Grid>
    )
}