import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import EESolarForm from '../../components/EESolarForm'
import SolarFinderForm from '../../components/SolarFinderForm'
import SolarFinderQuotesForm from '../../components/SolarFinderQuotesForm'

const Main = () => {
    return (
        <Switch>
            <Route exact path='/' component={EESolarForm} />
            <Route exact path='/solarfinder' component={SolarFinderForm} />
            <Route exact path='/solarfinderquotes' component={SolarFinderQuotesForm} />
            <Redirect from='*' to='/' />
        </Switch>
    )
}

export default Main